<template>
  <div class="Footer">
    © 2024 河南中昊供应链管理有限公司 &nbsp&nbsp  
    <span class="beian" @click="toPage">豫ICP备19037978号-1</span>
         
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {

    };
  },
  mounted(){
      this.initial()
  },
  methods: {
        async initial(){

        },
        toPage(){
          window.open('https://beian.miit.gov.cn/')
        }
  }
};
</script>

<style lang="scss" scoped>
.Footer{
    width: 100%;
    position: fixed;
    min-height: $footerHeight;
    bottom: 0;
    z-index: -1;
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      padding: 0 40px;
      box-sizing: border-box;
      text-align: center;
  }
  .beian{
    cursor: pointer;
  }
}
</style>
