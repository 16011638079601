import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

// 解决重复点击报错bug
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: HomeView,
    children: [
      {
        path: '/home',
        component: () => import('@/views/IndexPage/IndexPage.vue'),
      },
      {
        path: '/news',
        component: () => import('@/views/News/News.vue'),
      },
      {
        path: '/newsDetail',
        component: () => import('@/views/News/NewsDetail.vue'),
      },
      {
        path: '/source',
        component: () => import('@/views/Source/Source.vue'),
      },
      {
        path: '/transport',
        component: () => import('@/views/Transport/Transport.vue'),
      },
      {
        path: '/aboutus',
        component: () => import('@/views/AboutUs/AboutUs.vue'),
      },
      {
        path: '/statistics',
        component: () => import('@/views/Statistics/Statistics.vue'),
      }
    ]
  },
  {
    path: '/newsConfig',
    name: 'newsConfig',
    component: () => import('@/views/News/NewsLogin.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
