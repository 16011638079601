<template>
  <div class="Main">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "Main",
  data() {
    return {};
  },
  mounted() {
    this.initial();
  },
  methods: {
    async initial() {}
  }
};
</script>

<style lang="scss" scoped>
.Main {
  min-height: 100vh;
  background-color: #fff;
  // padding-top: $headerHeight;
  margin-bottom: $footerHeight;
  box-shadow: 0 20px 20px -10px rgba(49, 52, 57, 0.3),
    0 20px 30px rgba(0, 0, 0, 0.08);
}
</style>
