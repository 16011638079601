<template>
  <div class="home">
    <Header />
    <Main />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/layout/Header.vue';
import Main from '@/layout/Main.vue';
import Footer from '@/layout/Footer.vue';
export default {
  name: 'HomeView',
  components: {
    Header,
    Main,
    Footer
  }
}
</script>
<style lang="scss" scoped>
  p{
    color: $red;
  }
</style>
